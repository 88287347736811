<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data() {
        return {
            activeCustomQuestionnaire: null,
            mainTitle: null,
            mainText: null,
            userAnswers: [],
            finished: false,
            name: null,
            loading: false,
            savedSuccessfully: false,
            saveFailed: false,
            backToProfile: false,
            timerCount: 2700,
        }
    },
    computed: mapGetters(['user', 'companys']),
    methods: {
        ...mapActions(['getProfile', 'getCompanies', 'customQuestionnaireSaveAnswers']),

        async submit() {
            this.loading = true;
            this.name = this.activeCustomQuestionnaire.name
            this.finished = true;
            this.userAnswers = [];
            this.activeCustomQuestionnaire.questions.forEach(ele => {
                this.userAnswers.push(ele.userAnswer)
            });
            let data = {
                finished: this.finished,
                answers: this.userAnswers,
                name: this.name,
                id: this.user._id
            };

            let res = await this.customQuestionnaireSaveAnswers(data)
            if (res) {
                this.loading = false;
                this.validation = `Podaci uspešno sačuvani`;
                this.savedSuccessfully = true;
                setTimeout(() =>
                    this.savedSuccessfully = false, 5000
                );
                this.backToProfile = true;
            } else {
                this.loading = false;
                this.validation = `Neuspelo povezivanje sa bazom (code: PEA_182)`;
                this.saveFailed = true;
                setTimeout(() =>
                    this.saveFailed = false, 5000
                );

            }


        },
        isSingleChecked(j, item) {
            if (item.isSingleCheck) {
                for (let i = 0; i < item.userAnswer.length; i++) {
                    if (i == j) {
                        item.userAnswer[i].checked = true;
                    } else { item.userAnswer[i].checked = false; }
                }
            }

        },
        back() {
            this.$router.push('/profile')
        }
    },
    async created() {
        this.loading = true;
        await this.getProfile();
        await this.getCompanies();

        await this.companys.forEach(company => {
            if (company.name == this.user.type) {
                company.customQuestionnaire.forEach(item => {
                    if (item.active) {
                        this.activeCustomQuestionnaire = item;
                        this.mainTitle = item.title;
                        this.mainText = item.text;
                        this.activeCustomQuestionnaire.questions.forEach(item => {
                            if (item.isTextAnswer) {
                                item.userAnswer = "";
                            } else
                                if (item.isNumberAnswer) {
                                    item.userAnswer = []
                                    for (let i = 0; i < item.answers.length; i++) {
                                        item.userAnswer.push({ number: 0 })
                                    }
                                } else
                                    if (item.isCheckAnswer) {
                                        item.userAnswer = []
                                        for (let i = 0; i < item.answers.length; i++) {
                                            item.userAnswer.push({ checked: false })
                                        }
                                    }
                        })
                    }
                })
            }

        });

        await this.user.customUpitnikSchema.forEach(ele => {
            if (ele.name == this.activeCustomQuestionnaire.name && ele.finished == true) {
                this.backToProfile = true;
            }
        })

        this.loading = false;
    },
    watch: {
        timerCount: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
            },
            immediate: true // This ensures the watcher is triggered upon creation
        }
    }


}
</script>

<template>
    <div class="">
        <img src="@/assets/logonewest.svg" alt="" id="logo">
    </div>
    <div v-if="loading" class="loader"></div>
    <div v-if="activeCustomQuestionnaire != null && !backToProfile" class=" opis">
        <div class="d-grid">
            <h1>{{ this.mainTitle }}</h1>
            <textarea v-if="this.mainText" :rows="9" v-model="this.mainText" class="textarea-invisible" disabled></textarea>
            <h2 class="vreme mt-2">Vreme: {{ Math.floor(timerCount / 3600) }}:{{ Math.floor(timerCount / 60) % 60 }}:{{ timerCount % 60 }}
            </h2>
        </div>
    </div>
    <div v-if="activeCustomQuestionnaire != null && !backToProfile" class="card mx-auto">
        <div class="card-body">

        <div v-for="(item, i) in activeCustomQuestionnaire.questions" :key="i" class="d-grid p-3 answer-area">

            <h4>{{ item.questionText }}</h4>


            <div v-if="item.isPredetermentAnswers" class="d-flex justify-content-left form-group">
                <div class="d-grid">
                    <div v-for="(answer, j) in item.answers" :key="j" class="d-inline-flex">
                        <div class="d-flex align-items-center mx-2">
                            <div v-if="item.isCheckAnswer" class="form-check">
                            <input @change="isSingleChecked(j, item)" v-if="item.isCheckAnswer"
                                v-model="item.userAnswer[j].checked" type="checkbox"
                                class="border border-dark form-check-input rounded-circle" /></div>
                            <input v-if="item.isNumberAnswer" v-model="item.userAnswer[j].number" class="number-input"
                                type="number" :min="0" :max="item.userAnswer.length" />
                            <span class="ms-1">{{ answer }}</span>
                        </div>
                    </div>
                </div>
            </div>



            <div v-if="item.isTextAnswer" class="border my-3 mx-5">
                <div class="d-grid">
                    <textarea v-model="activeCustomQuestionnaire.questions[i].userAnswer" rows="10"></textarea>
                </div>
            </div></div>
            <!-- <hr v-if="i + 1 < activeCustomQuestionnaire.questions.length" class="mx-4 mt-4 mb-0" /> -->
        </div>
    </div>
    <button v-if="!backToProfile" @click="submit()" class="btn btn-primary mt-2 mb-4 w-50">Sačuvaj</button>
    <button @click="back()" class="btn btn-danger mt-2 mb-4 w-50 float-end">Povratak na profil</button>

    <div v-if="this.savedSuccessfully" class="bg-success p-3 rounded mb-3 text-white text-center fw-bold fixed-top py-5 ">
        Promene uspešno snimljene!</div>
    <div v-if="this.saveFailed" class="bg-danger p-3 rounded mb-3 text-white text-center fw-bold fixed-top py-5 ">Snimanje
        nije uspelo!</div>
</template>
<style scoped>
.opis {
    background-color: #bbffff;
    padding: 15px;
    border-style: solid;
    border-color: black;
    border-width: medium;
    border-radius: 15px;
}

.pointer {
    cursor: pointer;
}

.questions-area {
    background-color: #FFDEE9;
    background-image: linear-gradient(0deg, #FFDEE9 0%, #B5FFFC 100%);
}



.textarea-invisible {
    border: none;
    background-color: transparent;
    resize: none;
}

.number-input {
    width: 60px;
}

.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;

    margin: auto;
    margin-top: 100px;
    margin-bottom: 100%;

}
.answer-area{
    background-color: #99dd99;
  
  padding: 15px;
}
.answer-area:nth-child(even)
{
    background-color: #bbffbb;
  padding: 15px;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}</style>